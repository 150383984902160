import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import ContentSlide from '../../Common/Components/ContentSlide';
import Checkbox from '../../DesignSystem/Forms/Fields/Checkbox';
import Modal from '../../DesignSystem/Modal';
import SubjectIcon from '../../DesignSystem/SubjectIcon';
import Tooltip from '../../DesignSystem/Tooltip';
import SemesterSummary from './SemesterSummary';
import SkillLevelIcon from '../../Common/Components/SkillLevelIcon';

const MilestoneWizard = ({
  milestone,
  subjects,
  callbackFn,
  cancelFn,
  allMilestones,
  startDate,
  endDate,
}) => {
  const [updatedMilestone, setUpdatedMilestone] = useState({ ...milestone });
  const [selectedSubject, setSelectedSubject] = useState(subjects.find(s => s.id === milestone.subject_id));
  const [selectedVersion, setSelectedVersion] = useState();
  const [selectedContent, setSelectedContent] = useState();
  const [projectPrereqs, setProjectPrereqs] = useState([]);
  const [otherMilestones] = useState(allMilestones?.filter(m => m !== milestone));
  const [isForcedVersion, setIsForcedVersion] = useState(false);

  const { t } = useTranslation('MilestoneWizard');

  const toggleContent = (content, active) => {
    if (active) addContent(content);
    else removeContent(content.id);
  };

  useEffect(() => {
    if (!selectedSubject) return;

    const otherMilestoneWithVersion = otherMilestones.find(m => m.subject_id === selectedSubject.id);
    const forcedVersion = otherMilestoneWithVersion?.subject_version_id;

    if (forcedVersion) {
      setIsForcedVersion(true);
      selectVersion(forcedVersion);
      return;
    }

    setIsForcedVersion(false);

    if (updatedMilestone.contents.length > 0) {
      selectVersion(updatedMilestone.contents[0].subject_version_id);
      return;
    }

    selectVersion(selectedSubject.subject_versions[0].id);
  }, [selectedSubject]);

  const addContent = content => {
    if (updatedMilestone.contents.indexOf(content) > -1 || otherMilestones.find(m => m.contents.some(c => c.id === content.id)) != null) return true;

    if (content.content_type === 'Project' && content.prerequisite_course_ids?.length > 0) {
      setProjectPrereqs(content.prerequisite_course_ids
        .filter(course_id => !updatedMilestone.contents.some(c => c.id === course_id) && !otherMilestones.find(m => m.contents.some(c => c.id === course_id)))
        .map(course_id => selectedVersion.contents.find(c => c.id === course_id))
        .filter(course => course != null));
    } else {
      setProjectPrereqs([]);
    }

    setUpdatedMilestone(ms => {
      ms.contents = [...ms.contents, content].sort((a, b) => a.order_number - b.order_number);
      ms.subject_id = content.subject_id;
      ms.subject_version_id = content.subject_version_id;
      return { ...ms };
    });
  };

  const removeContent = content_id => {
    if (!updatedMilestone.contents.find(c => c.id === content_id)) return;

    setUpdatedMilestone(ms => {
      ms.contents = ms.contents.filter(c => c.id !== content_id);
      if (ms.contents.length === 0) {
        ms.subject_id = null;
        ms.subject_version_id = null;
      }
      return { ...ms };
    });
  };

  const previewContent = content => {
    setSelectedContent(content);
  };

  const selectSubject = subject_id => {
    setSelectedSubject(subjects.find(s => s.id === parseInt(subject_id, 10)));
    setSelectedContent(null);
  };

  const selectVersion = subject_version_id => {
    setSelectedVersion(selectedSubject.subject_versions.find(sv => sv.id === parseInt(subject_version_id, 10)));
  };

  const addProjectPrerequisites = () => {
    projectPrereqs.forEach(content => {
      addContent(content);
    });
  };

  const dismissProjectPrerequisites = () => {
    setProjectPrereqs([]);
  };

  const renderContent = content => {
    const otherMilestone = otherMilestones.find(m => m.contents.find(c => c.id === content.id));

    return (
      <React.Fragment key={`milestone-content-${content.id}`}>
        <div
          className={`u-flexRow ${otherMilestone ? 'b-displayDisabled' : ''}`}
          data-tip
          data-for={`content-locked-${content.id}`}
        >
          <Checkbox
            dynamic
            disabled={otherMilestone != null}
            onChange={(_, checked) => (otherMilestone ? (() => false) : toggleContent(content, checked))}
            wrapper={false}
            value={updatedMilestone.contents.some(c => c.id === content.id) || otherMilestone}
          />
          <ContentSlide
            content={content}
            showDetails={false}
            onClick={() => previewContent(content)}
            className="is-link u-grow1 m-bottom-s m-top-s u-pointer"
          />
        </div>
        {otherMilestone && (
          <Tooltip
            id={`content-locked-${content.id}`}
            text={t('inOtherMilestone', { content_type: content.content_type.toLowerCase(), milestone: otherMilestone.name })}
          />
        )}
      </React.Fragment>
    );
  };

  const renderContentSelection = () => (
    <>
      <div
        className="u-flexRow u-gridGap-s m-bottom-m u-alignCenter"
        data-tip
        data-for="subject-locked-message"
      >
        {selectedSubject && <SubjectIcon icon={selectedSubject.logo} colour={selectedSubject.colour} />}
        <Select
          className="Form-input u-grow1"
          classNamePrefix="react-select"
          defaultValue={selectedSubject ? { label: selectedSubject.name, value: selectedSubject.id } : null}
          onChange={option => selectSubject(option.value)}
          isDisabled={updatedMilestone.subject_id != null}
          placeholder={t('subjectPlaceholder')}
          options={subjects.map(subject => ({ label: subject.name, value: subject.id }))}
        />
        {selectedSubject && (
          <Select
            className="Form-input u-grow1"
            classNamePrefix="react-select"
            value={selectedVersion ? { label: selectedVersion.name, value: selectedVersion.id } : null}
            onChange={option => selectVersion(option.value)}
            isDisabled={updatedMilestone.subject_id != null || isForcedVersion}
            placeholder={t('subjectVersionPlaceholder')}
            options={selectedSubject.subject_versions.map(subject_version => ({
              label: subject_version.name,
              value: subject_version.id,
              status: subject_version.status,
            }))}
            filterOption={option => option.data?.status === 'live'}
          />
        )}
        {updatedMilestone.subject_id != null && (
          <Tooltip
            id="subject-locked-message"
            heading={t('subjectLockedTitle')}
            text={t('subjectLockedMessage')}
          />
        )}
        {updatedMilestone.subject_id == null && isForcedVersion && (
          <Tooltip
            id="subject-locked-message"
            heading={t('versionLockedTitle')}
            text={t('versionLockedMessage')}
          />
        )}
      </div>
      <div
        className={`ScrollBox ScrollBox--compact u-grow1 ${projectPrereqs.length > 0 ? 'b-uiDisabled' : ''}`}
        style={{ flexBasis: 400 }}
      >
        {selectedVersion?.contents.sort((a, b) => a.order_number - b.order_number).map(content => (
          renderContent(content)
        ))}
      </div>
    </>
  );

  const renderSidebar = () => {
    if (projectPrereqs.length > 0) return renderProjectPrerequisites();
    if (selectedContent) return renderContentDetails();
    if (!selectedSubject) return renderNoSubjectEmptyState();

    return renderNoContentEmptyState();
  };

  const renderProjectPrerequisites = () => (
    <div className="u-textCenter">
      <h4>{t('prerequisitesTitle')}</h4>
      <p>{t('prerequisiteDescription1')}</p>
      <p>{t('prerequisiteDescription2')}</p>
      {projectPrereqs.map(course => <ContentSlide key={`project-prerequisite-${course.id}`} content={course} />)}
      <div>
        <a onClick={addProjectPrerequisites} className="Button Button--primary">{t('buttonAddCourses')}</a>
        <a onClick={dismissProjectPrerequisites} className="Button Button--secondary">{t('buttonSkipPrerequisites')}</a>
      </div>
    </div>
  );

  const renderContentDetails = () => (
    <div>
      <img src={selectedContent.image_url} style={{ maxWidth: 200 }} />
      <h4>{selectedContent.name}</h4>
      <div className="Showcase Showcase--flex">
        <div className="Descriptor u-textBlack">
          <i
            className={`Descriptor-icon custom-icon icon-color-black icon-${selectedContent.content_type.toLowerCase()}`}
          />
          <span className="Descriptor-text">{selectedContent.content_type}</span>
        </div>
        <div className="Descriptor u-textBlack">
          <i className="Descriptor-icon fa fa-clock-o" />
          <span className="Descriptor-text">
            {t('durationMinutes', { minutes: selectedContent.duration })}
          </span>
        </div>
        <div className="Descriptor u-textBlack">
          <i className="Descriptor-icon custom-icon icon-cpe-cpd icon-color-black" />
          <span className="Descriptor-text">
            {t('cpdCredits', { count: selectedContent.cpd_credits })}
          </span>
        </div>
        {selectedContent.level && (
          <SkillLevelIcon level_name={selectedContent.level} className="Descriptor u-textBlack" icon_height="15px" />
        )}
      </div>
      <p>{selectedContent.description}</p>
    </div>
  );

  const renderNoContentEmptyState = () => (
    <div className="EmptyState">
      <img
        className="EmptyState-image EmptyState-image--medium"
        src="/assets/empty_states/milestone_wizard_content.png"
      />
      <h4 className="EmptyState-title">{t('noContentTitle')}</h4>
      <p className="EmptyState-description u-textCenter">{t('noContentDescription')}</p>
    </div>
  );

  const renderNoSubjectEmptyState = () => (
    <div className="EmptyState">
      <img className="EmptyState-image EmptyState-image--medium" src="/assets/empty_states/milestone_wizard_subject.png" />
      <h4 className="EmptyState-title">{t('noSubjectTitle')}</h4>
      <p className="EmptyState-description u-textCenter">{t('noSubjectDescription')}</p>
    </div>
  );

  return (
    <Modal className="Modal--admin Modal--dialog" closeCallback={() => cancelFn()}>
      <h3 className="Modal-title u-textCenter">
        {t('editMilestoneWithName', { name: milestone.name })}
      </h3>
      <div className="Modal-content">
        <SemesterSummary startDate={startDate} endDate={endDate} milestones={[...otherMilestones, updatedMilestone]} className="m-top-s u-textLeft" />
        <div className="Showcase has-2-columns">
          <div className="u-flexColumn">
            {renderContentSelection()}
          </div>
          <div className="Panel m-bottom-none">
            {renderSidebar()}
          </div>
        </div>
      </div>
      <div className="Modal-actions">
        <a className="Modal-action Button Button--secondary" onClick={() => cancelFn()}>{t('buttonCancel')}</a>
        <a className={`Modal-action Button Button--primary ${projectPrereqs.length > 0 || updatedMilestone.contents.length === 0 ? 'b-uiDisabled' : ''}`} onClick={() => callbackFn(updatedMilestone)}>{t('buttonSubmit')}</a>
      </div>
    </Modal>
  );
};

export default MilestoneWizard;
